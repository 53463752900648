
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "Plus Jakarta Sans";
        font-weight: 400;
        font-style: normal;
        src: url("https://cdn.rareblocks.xyz/collection/clarity/fonts/PlusJakartaSans-Regular.woff2")
        format("woff2");
    }

    @font-face {
        font-family: "Plus Jakarta Sans";
        font-weight: 400;
        font-style: italic;
        src: url("https://cdn.rareblocks.xyz/collection/clarity/fonts/PlusJakartaSans-Italic.woff2")
        format("woff2");
    }

    @font-face {
        font-family: "Plus Jakarta Sans";
        font-weight: 500;
        font-style: normal;
        src: url("https://cdn.rareblocks.xyz/collection/clarity/fonts/PlusJakartaSans-Medium.woff2")
        format("woff2");
    }

    @font-face {
        font-family: "Plus Jakarta Sans";
        font-weight: 500;
        font-style: italic;
        src: url("https://cdn.rareblocks.xyz/collection/clarity/fonts/PlusJakartaSans-MediumItalic.woff2")
        format("woff2");
    }

    @font-face {
        font-family: "Plus Jakarta Sans";
        font-weight: 600;
        font-style: normal;
        src: url("https://cdn.rareblocks.xyz/collection/clarity/fonts/PlusJakartaSans-SemiBold.woff2")
        format("woff2");
    }

    @font-face {
        font-family: "Plus Jakarta Sans";
        font-weight: 700;
        font-style: normal;
        src: url("https://cdn.rareblocks.xyz/collection/clarity/fonts/PlusJakartaSans-Bold.woff2")
        format("woff2");
    }
}
@layer components {
}
@layer utilities {
}

.bg-default {
    background-color: #7a19e9;
}

.bg-hover {
    background-color: #5f0cc2;
}